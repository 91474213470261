.btn {
  transition: transform 0.2s;

  &:active {
    transform: scale(0.95);
  }
}

.card .card-text {
  color: $gray-400;
}

.tooltip-primary {
  &.show {
    opacity: 1;
  }

  .tooltip-inner {
    border: 1px solid $primary;
    color: $primary;
  }

  &.bs-tooltip-top .tooltip-arrow::before {
    border-top-color: $primary;
  }

  &.bs-tooltip-end .tooltip-arrow::before {
    border-right-color: $primary;
  }

  &.bs-tooltip-bottom .tooltip-arrow::before {
    border-bottom-color: $primary;
  }

  &.bs-tooltip-start .tooltip-arrow::before {
    border-left-color: $primary;
  }
}
