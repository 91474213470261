/*
** VSCode Dark+ Theme
** (Code credit: https://github.com/Inter-Net-Pro)
*/

.cm-s-vscode-dark {
  &.CodeMirror {
    background: #1e1e1e;
    color: #e9e9e9;
  }

  .CodeMirror-cursor {
    border-left: 1px solid #bebebe;
  }

  div.CodeMirror-selected {
    background: #1e496c;
  }

  span.cm-meta, span.cm-keyword, span.cm-atom, span.cm-tag {
    color: #569cd6;
  }

  span.cm-number {
    color: #b5cea8;
  }

  span.cm-def, span.cm-variable-2, span.cm-property, span.cm-attribute {
    color: #9cdcfe;
  }

  span.cm-variable {
    color: #ddd6a3;
  }

  span.cm-variable-3, span.cm-type, span.cm-builtin {
    color: #A9B7C6;
  }

  span.cm-operator {
    color: #d4d4d4;
  }

  span.cm-string, span.cm-string-2 {
    color: #ce9178;
  }

  span.cm-comment {
    color: #6A9955;
  }

  span.cm-link {
    color: #287BDE;
  }

  span.cm-error {
    color: #BC3F3C;
  }

  span.cm-qualifier {
    color: #d7ba7d;
  }

  span.cm-bracket {
    color: #808080;
  }
}

.CodeMirror-hints.vscode-dark {
  color: #9c9e9e;
  background-color: #3b3e3f !important;

  .CodeMirror-hint-active {
    background-color: #494d4e !important;
    color: #9c9e9e !important;
  }
}
