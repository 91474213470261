.reveal {
  font-size: 25px;

  .slides {
    width: 95% !important;
    height: 95vh !important;
  }

  .slide-number {
    bottom: 40px;
    right: 40px;
    width: 44px;
    height: 44px;
    line-height: 44px;
    padding: 0;
    background: none;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    color: #42affa;

    a {
      display: inline-flex;
    }

    &-delimiter {
      margin: 0;
    }
  }

  code {
    font-family: $font-family-code;
  }
}

@include media-breakpoint-down(md) {
  .reveal {
    font-size: 20px;
  }
}

@include media-breakpoint-down(sm) {
  .reveal {
    font-size: 15px;
  }
}
