@import "~@fontsource/roboto-mono";

.CodeMirror {
  height: unset;
  padding: 1rem;
  border: 1px solid #6c757d;
  border-radius: 0.3rem;
  font-family: "Roboto Mono", monospace;

  &-lines {
    font-size: 1.25rem;
  }

  &-line {
    box-shadow: none !important;
  }
}

.dual-code-text-area > .code-mirror-block {
  width: 45% !important;
}

.code-mirror-block {
  position: relative;

  .code-mirror-action {
    position: absolute;
    top: 1rem;
    right: 1rem;
    z-index: 1;
  }

  .table-spacer {
    height: 10rem;
  }
}
