@import './variables.scss';
@import '~bootstrap/scss/bootstrap.scss';

@import './code-output.scss';
@import './customisation/bootstrap.scss';
@import './customisation/codemirror.scss';
@import './customisation/reveal.scss';
@import './customisation/vscode-dark.scss';
@import './js-lessons.scss';
@import './loader.scss';

html, body, #root {
  height: 100%;
  background: $dark;
  -webkit-user-select: none;
  user-select: none;
  cursor: default;
}

[hidden] {
  display: inherit !important;
}

@include media-breakpoint-down(md) {
  html {
    font-size: 14px;
  }
}

@include media-breakpoint-down(sm) {
  html {
    font-size: 12px;
  }
}
