@import "~@fontsource/amatic-sc";
@import "~@fontsource/caveat";

$bg-amongst-us: #111;
$bg-pasta-hut: #137;

.amongst-us, .pasta-hut {
  min-height: 100%;

  main {
    position: relative;
    z-index: 1;
    width: 90%;
    max-width: 400px;
  }

  .logo-heading {
    line-height: 0.8;
    font-size: 5rem;
  }
}

.amongst-us {
  background-color: $bg-amongst-us;

  .logo-heading {
    font-family: 'Amatic SC', monospace;
    text-shadow: 0 0 5px $bg-amongst-us;
  }

  .card {
    box-shadow: 0 0 5px $light;
  }

  .form-control:focus, .custom-select:focus, .form-check-input:focus {
    border-color: $primary !important;
  }

  .particles-wrapper {
    opacity: 0;
    transition: opacity 4s;

    &.loaded {
      opacity: 1;
    }
  }
}

.pasta-hut {
  background-color: $bg-pasta-hut;

  .logo-background {
    width: 12.5rem;
    height: 12.5rem;
    box-shadow: 0 0 1px 1px $light;
  }

  .logo-heading {
    font-family: 'Caveat', cursive;
    text-shadow: 0 0 5px #baa;
    transform: rotate(-5deg) skew(-5deg) translate(-0.375rem, 1.75rem);
  }

  .card {
    box-shadow: 0 0 2px #111;
  }

  hr {
    border-top-width: 2px;
    border-top-style: dotted;
  }

  .form-control:hover, .form-select:hover, .form-check-input:hover {
    background-color: rgba(0, 0, 0, 0.03) !important;
  }

  .form-control:focus, .form-select:focus, .form-check-input:focus {
    border-color: #007bff40; 
    background-color: #fff !important;
    box-shadow: 0 0 0 0.25rem #007bff40;
  }

  .form-check-input:checked {
    background-color: #007bff !important;
  }

  .corner-image {
    position: fixed;
    width: 500px;
    height: 500px;
    border-radius: 50%;
    background-position: center;
    background-size: 100%;
    box-shadow: 0 0 5px #111;
    opacity: 0;
    transition: all 1.75s;

    &.visible {
      opacity: 1;
    }
  }

  #bottom-left-image {
    bottom: -500px;
    left: -500px;
    /* Photo credit: Nicholas Grande @ndg_visuals on Unsplash */
    background-image: url('../assets/vegetable-pasta.png');

    &.visible {
      bottom: -250px;
      left: -250px;
    }
  }

  #top-right-image {
    top: -500px;
    right: -500px;
    /* Photo credit: PNGWing (photographer/artist unknown) */
    background-image: url('../assets/tomato-pasta.png');

    &.visible {
      top: -250px;
      right: -250px;
    }
  }
}
